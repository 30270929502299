<template>
  <div class="contact">
    <ContactForm></ContactForm>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from '@/components/ContactForm.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'Contact',
  components: {
    ContactForm,
    Footer
  }
}
</script>