<template>
     <v-container fluid>
        <v-layout row class="frame">
            <v-flex text-center xs12 md6>
                 <div class="about-img-frame">
                    
                 </div>
            </v-flex>
            <v-flex d-flex flex-column align-center xs12 md6 >
                <div class="about-text">
                    <p>kontakt</p>
                    <h2>Zakažite termin</h2>
                 </div>
                 <div id="contact-form" class="contact-form">
                    <div class="form">
                        <input required name="name" v-model="name" placeholder="Ime" type="text" autocomplete="off" @input='onInputForm'>
                        <span v-if="formErrors.name" class="invalid-form">Unesite ime</span>
                        <input required name="surname" v-model="surname" placeholder="Prezime" type="text" autocomplete="off" @input='onInputForm'>
                        <span v-if="formErrors.surname" class="invalid-form">Unesite prezime</span>
                        <input required name="email" v-model="email" placeholder="E-mail" type="email" autocomplete="off" @input='onInputForm'>
                        <span v-if="formErrors.email" class="invalid-form">Unesite email</span>
                        <textarea name="message" v-model="text" rows="4" placeholder="Poruka" @input='onInputForm'></textarea>
                        <span v-if="formErrors.text" class="invalid-form">Unesite vašu poruku</span>
                        <span class="google-notice">
                            Ovaj sajt je zaštićen Google Recaptcha tehnologjiom čiji 
                            <a target='_blank' href="https://policies.google.com/privacy?hl=en">Privacy policy</a> i
                            <a target='_blank' href="https://policies.google.com/terms?hl=en">Terms of Service</a> prihvatate slanjem ove forme.
                        </span>
                        <div class="contact-btn-holder">
                            <button @click="sendForm" class="contact-btn">Pošaljite poruku<span class="send-arrow"><img src="../assets/arrow-black.png" alt="arrow-right"></span></button>
                            <transition name="fade-transition">
                                <div class="info-msg" v-if="formSent && !isAnyError && !isFormEdited && !errorResponse">Vaša poruka je uspešno poslata</div>
                                <div class="info-msg" v-if='errorResponse'>Vaša poruka nije poslata.</div>
                            </transition>
                        </div>
                        
                    </div>
                    <div class="contact-info">
                        <p>E: norapmu@gmail.com</p>
                        <p>A: Gospodar Jovanova 43</p>
                        <p>T: 011 2632 116</p>
                        <p>M: 064 00 15 005</p>
                    </div>
                </div>
            </v-flex>
            <v-flex xs12 md12 lg12>
                <div class="map-img-frame">
                    
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>



<script>

import axios from "axios"


export default {
    name: 'ContactForm',    
    data: function() {
        return {
            name:'',
            surname:'',
            email:'',
            text:'',
            formErrors:{
                name: false,
                surname: false,
                email: false,
                text: false
            },
            formSent: false,
            isFormEdited:false,
            errorResponse: "",
        }
    },
    computed:{
        isAnyError() {
            return Object.values(this.formErrors).every(Boolean);
        }
    },
    methods:{
        sendForm(){
            this.isFormEdited = false;
            this.formErrors.name = false;
            this.formErrors.surname = false;
            this.formErrors.email = false;
            this.formErrors.text = false;
            if(this.name.trim()===''){
                this.formErrors.name = true
            }
            if(this.surname.trim()===''){
                this.formErrors.surname = true
            }
            if(this.email.trim()===''){
                this.formErrors.email = true
            }
            if(this.text.trim()===''){
                this.formErrors.text = true
            }
            
            grecaptcha.execute('6LfbagcdAAAAAEEhGoGto7rZiiU14Se8pXCvkxMh', {action: 'submit'}).then((token) => {
                // For localhost testing, full link is: http://331k122.mars1.mars-hosting.com/api/contact-form
                axios.post("/api/contact-form",
                {
                    name:this.name,
                    lastname:this.surname,
                    email:this.email,
                    text:this.text,
                    token: token
                }).then((res) => {
                    this.formSent = true;
                    console.log(res.data)
                    this.errorResponse = res.data.message;
                    this.name = "";
                    this.surname = "";
                    this.email = "";
                    this.text = "";
                })
            });
            


            
        },
        onInputForm() {
            this.isFormEdited = true;
            this.errorResponse = "";
        },
    },
}

</script>


<style scoped>

.google-notice {
    font-size: 10px;
}

.frame{
    background-color: #ffe7d8;;
}


.about-text{
    width: 68%;
}

.about-text p{
    font-family: "Quicksand";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.97px;
    color: #000000;
    text-transform: uppercase;
    margin-top: 72px;
}

.about-text h2{
    font-family: "IvyMode Lt";
    font-size: 54px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 3px;
    color: #000000;
    margin: 26px 0 29px 0;
}

.about-img-frame{
    background-image: url("../assets/prvoklasna-usluga-kontak.jpg");
    height: 100%;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input{
    width: 480px;
    height: 60px;
    margin: 29px 32px 15px 0;
    padding: 16px 30px 16px 30px;
    opacity: 0.9;
    background-color: #ffffff;
}

textarea{
    width: 480px;
    height: 160px;
    margin: 15px 32px 0 0;
    padding: 16px 30px;
    opacity: 0.9;
    background-color: #ffffff;
}

::placeholder{
     font-family: "IvyMode Lt";
}

.contact-btn-holder{
    width: 100%;
    margin: 50px 0 79px;
}

.contact-btn{
    font-family: "IvyMode Lt";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.33px;
    color: #000000;
    margin-right: 10px;
}

.contact-btn:hover{
    color: #8a6e49;
}

.invalid-form{
    text-align: right;
    color: red;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "IvyMode Lt";
    letter-spacing: 1.33px;
}

.google-notice{
    font-family: "IvyMode Lt";
    margin-top: 10px;
}

.info-msg{
    font-family: "IvyMode Lt";
    margin-top: 20px;
}

.send-arrow{
    padding-left: 10px;
}

.invalid-form:nth-last-child(3){
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-info{
    margin-bottom: 72px;
}

.contact-info p{
    font-family: "IvyMode Semi Bd";
}

.map-img-frame{
    background: url("../assets/mapa.jpg") center center / cover;
    height: 500px;
    width: auto;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: all .3s ease;
}

.fade-transition-enter,
.fade-transition-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 550px){
    input,textarea{
        width: 100%;
        margin: 15px 0 0 0;
        padding: 16px 30px 16px 30px;
    }

    .contact-info{
        text-align: center;
    }

    .contact-form{
        width: 90%;
        text-align: center;
    }

    .about-text{
        padding: 40px 0 20px;
    }

    .about-text p{
        display: none;
    }

    .about-text h2{
        font-size: 32px;
    }
}



</style>